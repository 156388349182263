@import url("../node_modules/@changey/react-leaflet-markercluster/dist/styles.min.css");
.marker-cluster-custom-big {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #52c41a;
  font-size: 140%;
  border-style: solid;
  border-width: 0px;
  border-radius: 50%;
  box-shadow: 0 0 20px #237804;
  color: #f5f5f5;
  text-align: center;
}

.sliderStyles {
  box-shadow: 3px 0px 10px #252525;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #141414 !important;
  color: hsla(0, 0%, 100%, 0.85);
}

body {
  overscroll-behavior-y: contain;
  margin: 0px !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.marker-cluster-custom-medium {
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #fa8c16;
  font-size: 120%;
  border-style: solid;
  border-width: 0px;
  border-color: rgb(51, 51, 51);
  box-shadow: 0 0 15px #ad4e00;
  border-radius: 50%;
  color: #f5f5f5;
  text-align: center;
}

.marker-cluster-custom-small {
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #f5222d;
  font-size: 110%;
  border-style: solid;
  border-width: 0px;
  border-radius: 50%;
  box-shadow: 0 0 12px #a8071a;
  color: #f5f5f5;
  text-align: center;
}

.carpark-span {
  background-color: #1890ff;
  font-size: 130%;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(51, 51, 51);
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 15ch;
  box-shadow: 0 0 10px #252525;
  color: #f5f5f5;
  text-align: center;
}

.ant-message-notice-content {
  background-color: #141414 !important;
  color: hsla(0, 0%, 100%, 0.85);
}

.carparkEimg {
  width: 20px;
  height: 20px;
}

.carparkE-div {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  width: 35px;
  height: 35px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(51, 51, 51);
  border-radius: 50%;
  box-shadow: 0 0 10px #08979c;
  background-color: #13c2c2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaflet-marker-icon {
  height: fit-content !important;
}

.carparkLong-div {
  width: 35px;
  height: 35px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(51, 51, 51);
  border-radius: 50%;
  box-shadow: 0 0 10px #252525;
  background-color: #1890ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carparkLong-img {
  width: 25px;
  height: 25px;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-in-center-fade {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.leaflet-popup-content-wrapper {
  background-color: #ffae00 !important;
  border-radius: 5px !important;
  color: white !important;
  border-style: solid;
  border-width: 1px !important;
  border-color: rgb(51, 51, 51) !important;
  padding: 5px !important;
}

.leaflet-popup-content {
  margin: 0px !important;
  text-align: center !important;
  overflow: hidden !important;
}

.leaflet-popup-tip {
  background-color: #ffae00 !important;
  border-style: solid;
  border-width: 1px !important;
  border-color: rgb(51, 51, 51) !important;
}

.desktop ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #1f1f1f;
}

.desktop ::-webkit-scrollbar {
  width: 10px;
  background-color: #8c8c8c;
}

.desktop ::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #8c8c8c;
}

.nearbyCarpark:hover {
  background-color: #164c7e;
}

.nearbyCarpark:active {
  background-color: #2b4acb;
}

.autoCompleteStyles > .ant-select-selector {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.ant-select-item-empty {
  padding: 0.5ch !important;
}

.nearbyCarpark {
  transition: 0.4s;
}

.Drawer {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: absolute;
  width: 100%;
  max-width: 90%;
  top: 70% !important;
  max-height: calc(var(--vh, 1vh) * 100) !important;
  height: 100%;
  transform: translate3d(0, 0, 0);
  background-color: #141414;
  color: hsla(0, 0%, 100%, 0.85);
  border-top: 1px solid transparent;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  pointer-events: auto !important;
  box-shadow: 0px -5px 10px 3px #595959;
}

.tabStyle {
  position: absolute;
  z-index: 15;
  bottom: 0;
  width: 100vw;
  background-color: #141414 !important;
  border-top: 1px solid transparent;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.leaflet-bottom {
  bottom: 50px !important;
}

.tabStyle .ant-tabs-nav {
  margin-bottom: 2px !important;
}

.navStyle {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-content: center;
  width: 10ch;
  text-align: center;
  font-size: 105%;
}
.ant-tabs-nav {
  margin-top: 0 !important;
}
.css-3pxmmn {
  background-color: transparent !important;
  height: 94% !important;
}

.paper {
  background-color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 1rem;
  color: #fff;
  transition: 0.25s;
  cursor: pointer;
  margin-bottom: 3ch;
}
.paper.selected {
  background-color: #0958d9;
  cursor: default !important;
}
.paper:hover:not([class*=" "]) {
  transform: translate(6px, -6px);
  opacity: 0.8;
}

@media (max-width: 768px) {
  .Drawer {
    width: 100%;
    max-width: 100%;
  }
}

.sliderOverride .ant-slider-track {
  height: 16px !important;
}
.sliderOverride .ant-slider-rail {
  height: 16px !important;
}
.sliderOverride .ant-slider-step {
  height: 16px !important;
}
.sliderOverride .ant-slider-dot {
  height: 16px !important;
  width: 16px !important;
}
.sliderOverride .ant-slider-handle::after {
  height: 16px !important;
  width: 16px !important;
}
.sliderOverride .ant-slider-mark {
  margin-top: 8px !important;
}

.mobileAuto .ant-select-clear {
  font-size: 24px !important;
  height: 36px !important;
  width: 36px !important;
  top: 2px !important;
  margin-top: 0px !important;
  padding-top: 4px !important;
  padding-right: 6px !important;
  padding-left: 6px !important;
  right: 4px !important;
  margin: auto !important;
  background-color: #262626 !important;
}

.MobileBody {
  overflow: hidden;
  height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) !important;
}

.mobileDrawerContent {
  padding: 10px;
  padding-top: 15px;
  background-color: #141414;
  border: 1px solid transparent;
  border-radius: 10px;
  padding-bottom: 10vh;
}

.leaflet-control-locate {
  box-shadow: 0px 0px 10px 5px #595959 !important;
  border: 0px solid transparent !important;
  border-radius: 10px !important;
}

.leaflet-control-locate a {
  height: 45px !important;
  width: 45px !important;
  color: #fafafa !important;
  border: 0px solid transparent !important;
  border-radius: 10px !important;
}

.fa-location-arrow {
  font-size: 26px !important;
}

.leaflet-control-locate.active a {
  color: #d8bd14 !important;
}

.leaflet-bar-part {
  padding-top: 8px;
  background-color: #177ddc !important;
  transition: 0.38s;
}

.leaflet-bar-part:hover {
  background-color: #164c7e !important;
}

.nearbyCarparkButtons {
  animation: scale-in-center-fade 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  box-shadow: 0px 0px 10px 5px #595959 !important;
}

.nearbyCarparkButtons:hover {
  background-color: #ad6800 !important;
}

@keyframes fade-in {
  from {
    background-color: rgba(23, 101, 173, 0);
  }
  to {
    background-color: rgba(23, 101, 173, 0.8);
  }
}

@keyframes fade-in-vanilla {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-animation {
  animation: fade-in-vanilla 0.3s linear both;
}

.mapSelector .ant-radio-wrapper-checked {
  animation: fade-in 0.3s linear both;
  border: 0px solid transparent;
  padding-left: 2ch;
  border-radius: 10px;
}

.ant-radio-wrapper {
  padding-left: 2ch !important;
}
